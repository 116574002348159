import { Stack, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { LanguageContext } from '../../../../providers/context/language';
import alcampoLogo from '../../../../static/alcampo/logo.svg';
import logo from '../../../../static/LOGO-DrookyGirl.png';
import LogoAppStore from '../../../../static/logo_app_store.svg';
import LogoAppStoreEs from '../../../../static/logo_app_store_es.svg';
import LogoPlayStore from '../../../../static/logo_play_store.svg';
import LogoPlayStoreEs from '../../../../static/logo_play_store_es.svg';
import { isAlcampo } from '../../../../utils/language';
import Signin from './Signin';

const useStyles = makeStyles((theme) => ({
  root: {
    // height: '100%',
    backgroundColor: '#ffffff',
  },
  title_text: {
    fontSize: '2.1rem',
    marginBottom: '100px',
    paddingLeft: 30,
    paddingRight: 30,
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.7rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1.4rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.2rem',
    },
  },
  mobileAppText: {
    fontSize: '1.5rem',
    paddingTop: 50,
    paddingLeft: 30,
    paddingRight: 30,
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.4rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1.2rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
  },
  boxLogo: {
    marginTop: '2%',
  },
  iosLogo: {
    width: '200px',
  },
  androidLogo: {
    width: '200px',
    height: '64px',
  },
}));
export default function Login() {
  const { t } = useTranslation(null, {
    keyPrefix: 'login.signIn',
  });
  const { language } = useContext(LanguageContext);
  const isMobileApp = process.env.REACT_APP_IS_MOBILE_APP === 'true';
  const classes = useStyles();
  const [otp, setOtp] = useState(false);

  return (
    <Stack
      spacing={2}
      justifyContent="center"
      alignItems="center"
      sx={{ height: '100%', width: '100%', backgroundColor: '#fff' }}
    >
      {!isMobileApp ? (
        <Typography
          color={'primary'}
          variant={'h4'}
          className={classes.title_text}
        >
          <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
            {t('mobile.title1')}
            <span style={{ display: 'inline-block' }}>
              {t('mobile.title2')}
            </span>
          </Box>
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            {t('title1')}
            <br />
            {t('title2')}
            <span style={{ display: 'inline-block' }}>
              {t('title3')}
            </span>
          </Box>
        </Typography>
      ) : (
        <>
          <img
            style={{
              width: '100%',
              maxWidth: '800px',
              marginBottom: '40px',
            }}
            src={isAlcampo() ? alcampoLogo : logo}
            alt={isAlcampo() ? 'Alcampo Mascotas' : 'Drooky'}
          />
          <Typography fontFamily={'Supply'} variant={'h1'}>
            {t('connexion')}
          </Typography>
        </>
      )}
      <Signin t={t} otp={otp} setOtp={setOtp} />
      {!isMobileApp && (
        <>
          <Typography
            color={'primary'}
            variant={'h4'}
            className={classes.mobileAppText}
          >
            <span style={{ display: 'inline-block' }}>
              {t('mobile.mobileApps')}
            </span>
          </Typography>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="center"
            flexWrap="wrap"
          >
            <a
              href={process.env.REACT_APP_MOBILE_IOS_URL}
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={
                  language === 'fr' ? LogoAppStore : LogoAppStoreEs
                }
                alt="AppStore"
                className={classes.iosLogo}
              />
            </a>
            <a
              href={process.env.REACT_APP_MOBILE_ANDROID_URL}
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={
                  language === 'fr' ? LogoPlayStore : LogoPlayStoreEs
                }
                alt="PlayStore"
                className={classes.androidLogo}
              />
            </a>
          </Stack>
        </>
      )}
    </Stack>
  );
}
